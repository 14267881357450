section.benefits-list {
  .wp-block-cover {
    padding-block: 66px;
    padding-inline: 0;
  }
  h2 {
    font-size: 26px;
    line-height: 1.0526315789;
    letter-spacing: 0.38px;
    font-weight: 600;
    margin: 0 0 1.07em 0;
  }
  .section-heading  {
    text-decoration: underline;
    text-decoration-color: #5BC2E7;
    text-decoration-thickness: 2px;
    text-underline-offset: 0.75em;
  }
  .group-grid {
    padding-top: 60px;
    grid-column: span 1;
    > .wp-block-group__inner-container {
      gap: 25px;
    }
  }
  .group-item {
    h4 {
      font-family: "Roboto", sans-serif !important;
      letter-spacing: -0.18px;
      font-weight: 500;
      line-height: 1.666;
      text-transform: none;
    }
    > .wp-block-group__inner-container {
      display: flex;
      flex-direction: column;
      gap: 34px;
      figure {
        width: 75px;
        height: auto;
      }
      .wp-block-group {
        flex: 1;
      }
    }
  }
  @media screen and (min-width: 960px) {
    .wp-block-cover {
      padding-block: 100px;
    }

   .group-grid {
    grid-column: 1/-1;
    > .wp-block-group__inner-container {
      gap: 15px 90px;
      grid-template-columns: repeat(2, minmax(0, 540px));
    }
   }
   .group-item {
    > .wp-block-group__inner-container {
      flex-direction: row;
    }
   }
   h2 {
    font-size: 38px;
  }
  }
}