section.choose-path {
  background-color: #FFFFFF;
  padding-block: 100px;
  position: relative;
  &::before {
    content: '';
    background-image: url("/src/images/background-accent.png");
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 27.75vw;
    height: 34.3125vw;
    max-width: 444px;
    max-height: 550px;
  }
  .block-container {
    position: relative;
  }
  .panel-heading {
    background: transparent linear-gradient(172deg, #4BC1EF 0%, #02629B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding:  0.71em 1.18em 0.56em;
    margin-inline: auto;
    position: absolute;
    top: -44px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: calc(100% - 120px);
    
  }
  .group-path {
    background-color:  rgba(222, 243, 250, 0.7);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 30px 25px;
    h2 {
      color: #003360;
      margin-bottom: 20px;
      text-wrap-style: balance;
      font-family: 'Korolev Bold', sans-serif !important;
    }
    p {
      color: #2F2F2F;
      font-size: 18px;
      line-height: 1.666;
      letter-spacing: -0.18px;
      font-weight: 500;
      max-width: 680px;
      margin-inline: auto;
    }
    &.enterprise-path {
      background-color: rgba(208, 208, 206, 0.3);
    }
  }
  @media screen and (min-width:960px) {
    padding-top: 170px;
    .panel-heading {
      max-width: 800px;
    }
    .group-path {
      padding: 60px 50px;
      position: relative;
      &:before {
        content: '';
        width: 286px;
        height: 126px;
        top: -126px;
        position: absolute;
      }
      &:first-child {
        grid-area: 1 / 1 / 2 / 7;
        &:before {
          right: 0;
          background-image: url("/src/images/blue-line.svg");
          
        } 
      }
      &:nth-child(2) {
        grid-area: 1 / 7 / 2 / 13;
        &:before {
          background-image: url("/src/images/red-line.svg");
          left: 0;
        }
      }
      &:last-child {
        grid-area: 2 / 1 / 3 / 13;
      }
    }
  }
}