section.section-hero {
  position: relative;
  .wp-block-cover {
    padding: 80px 0 0;
  }
  
  h1 {
    color: #5BC2E7;
    font-size: 40px;
    line-height: 1.0333;
    margin: 0 0 22px 0;
    letter-spacing: 0;
  }
  p {
    font-size: 22px;
    line-height: 1.545;
    letter-spacing: -0.22px;
    color: #fff;
    margin: 0 0 20px 0;
    text-wrap-style: balance;
  }
  .group-content {
    grid-row: 1;
  } 
  .group-image {
    grid-row: 2;
  }

  @media screen and (min-width: 960px) {
    .wp-block-cover {
      padding-top: 112px;
      padding-bottom: 140px;
    }
    .group-content {
      grid-area: 1 / 1 / 2 / 7;
    }
    .group-image {
      grid-area: 1 / 8 / 2 / 13;
      figure {
        position: absolute;
        right: 0;
        top: 50%;
        width: 50%;
        transform: translateY(-50%)
      }
    }
    h1 {
      font-size: 60px;
    }
  }
}