@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.page-template-template-block-landing-page {
  .block-container {
    max-width: 1220px;
    padding-inline: 30px;
    margin: 0 auto;
  }
  header .grid-container, 
  .grid-container > .wp-block-group__inner-container {
    display: grid;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      gap: 20px;
      @media screen and (min-width: 960px) {
        grid-template-columns: repeat(12,1fr);
        gap: 40px;
      }
    }
    header .block-container {
      max-width: 1600px;
    }
    header .grid-container {
      align-items: center;
    }
    @media screen and (min-width: 960px) {
      header .logo {
        grid-area: 1 / 1 / 2 / 6;
      }
      header .buttons {
        grid-area: 1 / 9 / 2 / 13;
      }
    }
  }
  header .logo {
    grid-column: 1/-1;
  }
.cms-content {
  font-family:  "Roboto", sans-serif;
  h2 {
    font-weight: normal;
    font-family: 'Korolev Medium' !important;
    font-size: 26px;
    line-height: 1.0526315789;
    letter-spacing: 0.38px;
    margin: 0 0 1.07em 0;
    &.panel-heading {
      margin-bottom: 0.47em;
    }
  }
  p, ul li, ol li {
    font-size: 16px;
    letter-spacing: -0.16px;
    line-height: 1.625;
  }
  .wp-block-button {
    .wp-element-button {
      background-color: #E5625E;
      border: 2px solid #E5625E;
      border-radius: 10px;
      font-family: "Korolev", sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      padding: 9px 34px;
      letter-spacing: 0.42px;
      text-decoration: none;
      transition: background-color .3s ease, border-color .3s ease, color .3s ease;
      &:hover {
        background-color: #003360;
        border-color: #003360;
        color: #fff;
      }
     
    }
    &.alternate-color {
      .wp-element-button {
       background-color: #5BC2E7;
       border-color: #5BC2E7;
       &:hover {
        background-color: #003360;
        border-color: #003360;
        color: #fff;
      }
      }
     }
     &.tertiary-color {
      .wp-element-button {
        background-color: #1277ad;
        border-color: #1277ad;
        &:hover {
          background-color: #003360;
          border-color: #003360;
          color: #fff;
        }
      }
     }
  }
  @media screen and (min-width: 960px) {
    h2 {
      font-size: 38px;
    }
    
  }
 
}

