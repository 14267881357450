/* #### Generated By: http://font.download #### */

@font-face {
  font-family: 'Korolev Heavy';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Heavy.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Thin';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Thin.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Light Italic';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Light-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Light';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Medium-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Medium';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Bold';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Bold-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Korolev Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: url('/src/fonts/Korolev-Heavy-Italic.woff') format('woff');
  }