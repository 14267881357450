.web-banner  {
  position: relative;
  .banner-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 10px;
  }
  .group-banner {
    position: relative;
    padding: 20px;
    background: rgb(91,194,231);
    background: radial-gradient(circle, rgba(91,194,231,1) 0%, rgba(2,98,155,1) 100%);
    
    .group-content-wrapper {
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: 960px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          max-width: 1440px;
          margin: 0 auto;
          .group-text-col-1 {
            flex: 1;
          }
          .group-image {
            order: unset;
            flex: 1;
            img {
              max-width: 100%;
            }
          }
          .group-text-col-2 {
            max-width: 560px;
            width: 40%;
          }
        }
      
    }
    p {
      color: #fff;
      font-size: 16px;
      letter-spacing: 4px;
      margin: 0;
      font-family: 'Korolev Medium';
    }
    h2 {
      font-family: 'Korolev Bold' !important;
      font-weight: 600;
      color: #fff;
      font-size: 18px;
      letter-spacing: 1.28px;
      margin: 0;
      line-height: 1;
      text-wrap-style: balance;
      strong {
        font-family: 'Korolev Heavy' !important;
      }
      a {
        color: inherit;
      }
      // &.no-wrap {
      //   text-wrap-mode: nowrap;
      // }
    }
    .group-image {
      max-width: 300px;
      order: 1;
      img {
        margin: 0 auto;
      }
    }
    @media screen and (min-width: 1200px) {
      p {
        font-size: 20px;
      }
      h2 {
        font-size: 32px;
      }
    }
  }
}

