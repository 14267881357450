section.section-two-col {
  padding-block: 100px;
  &.blue-gradient {
    background: transparent linear-gradient(137deg, #4BC1EF 0%, #02629B 100%) 0% 0% no-repeat padding-box;
    h2,
    h3,
    p,
    ul li,
    ol li {
      color: #fff; 
    }
    
  }
  h2 {
    text-wrap-style: balance;
  }
  .p-large {
    font-size: 16px;
    letter-spacing: -0.22px;
    line-height: 1.25;
    font-weight: 600;
    margin: 0 0 1.07em 0;
  }

  p {
    font-size: 16px;
    letter-spacing: -0.16px;
    line-height: 1.625;
  }
  .group-content, .group-image {
    grid-column: span 1;
  }
  @media screen and (min-width: 960px) {
    padding-block: 100px;
    .group-content {
      grid-area: 1 / 1 / 2 / 7;
      padding-right: 40px;
    }
    .group-image {
      grid-area: 1 / 6 / 2 / 13;
      transform: translateX(80px);
      .wp-block-image {
        padding: 0 30px;
      }
    }
    
    .p-large {
      font-size: 22px;
    }
  }
}