.section-product-carousel {
  background: #013360;
  padding: 40px 20px;

  .product-carousel.swiper {
    max-width: 1360px;
    margin: 0 auto;
    position: relative;
    width: 100%;

    .swiper-wrapper {
      height: auto;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;

    h2,
    p {
      color: #fff;
    }

    p {
      font-size: 22px;
      line-height: 1.454;
      letter-spacing: -0.35px;
      margin-bottom: 10px;
    }

    .slide-image {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        max-width: 360px;
        margin: 0 auto;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 20px #0000001a;
      }

      @media screen and (min-width: 640px) {
        img {
          max-width: 500px;
        }
      }

      @media screen and (min-width: 1000px) {
        img {
          max-width: 360px;
        }

        h2 {
          display: none;
        }
      }
    }

    .slide-text {
      h2 {
        display: none;
      }

      img {
        padding-top: 30px;
      }

      @media screen and (min-width: 1000px) {
        padding-top: 46px;
        max-width: 455px;

        h2 {
          display: block;
        }
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      align-items: center;
      gap: 40px;

      @media screen and (min-width: 640px) {
        padding-bottom: 0;
        padding-inline: 60px;
      }

      @media screen and (min-width: 1000px) {
        flex-direction: row;
        gap: 95px;
        padding-inline: 0;
        align-items: start;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    color: transparent;

    &::after {
      width: 40px;
      height: 40px;
      background-size: cover;
    }
  }

  .swiper-button-next {
    top: unset;
    bottom: 0;
    left: 70px;

    @media screen and (min-width: 640px) {
      top: var(--swiper-navigation-top-offset, 50%);
      bottom: unset;
      left: auto;
    }

    &::after {
      background-image: url("/src/images/btn-right.svg");
    }
  }

  .swiper-button-prev {
    top: unset;
    bottom: 0;
    left: 0;

    @media screen and (min-width: 640px) {
      top: var(--swiper-navigation-top-offset, 50%);
      bottom: unset;
      left: auto;
    }

    &::after {
      background-image: url("/src/images/btn-left.svg");
    }
  }
}
