section.cta-background-img {
 
  .wp-block-cover {
    padding-block: 66px;
    padding-inline: 0;
  }
  h2, h3, p, ol li, ul li {
    color: #2F2F2F;
  }

 
  .p-large {
    font-size: 16px;
    letter-spacing: -0.22px;
    line-height: 1.25;
    font-weight: 600;
    margin: 0 0 1.07em 0;
  }

  p {
    font-size: 16px;
    letter-spacing: -0.16px;
    line-height: 1.625;
    text-wrap-style: balance;
  }
  .group-content {
    grid-column: span 1;
  }
  @media screen and (min-width: 960px) {
    .wp-block-cover {
      padding-block: 100px;
    } 
    .group-content {
      grid-area: 1 / 1 / 2 / 8;
    }
   
    .p-large {
      font-size: 22px;
    }
  }
}